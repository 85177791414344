.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.create-plan {
  background-color: #4e72b7;
  border: 1px solid #4e72b7;
  color: white;
  font-size: 1.2rem;
  padding: 10px 15px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  margin: 10px;
}
.completed-projects-feedback {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.project-avatar-feedback {
  display: flex;
  align-items: center;
}
.project-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.plan-card-info {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.plan-desc {
  display: block;
  padding-top: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

li.plan-card-list {
  margin-bottom: 20px;
  text-align: justify;
}

span.plan-tag-title {
  margin-right: 20px;
  font-weight: 600;
  font-size: larger;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.dev-type {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
  display: block;
}
.skill-tag {
  margin-right: 20px;
  display: block;
  margin-bottom: 15px;
}
.skill {
  margin-right: 20px;
  background: lightgray;
  border-radius: 7px;
  padding: 7px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
}

.projects-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 90%; */
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
  /* margin-left: 78px; */
}
.project-card-tag {
  text-align: center;
  font-weight: 600;
  font-size: larger;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin-top: 0px;
}
.project-card-image {
  margin-bottom: 25px;
}
.projects-list-header {
  margin-top: 10px;
  margin-bottom: 27px;
  text-align: center;
  padding-bottom: 15px;
}

.project-feedback-tag {
  margin-right: 20px;
  font-weight: 600;
  font-size: larger;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: left;
  display: block;
  margin-left: 40px;
}
.project-feedback-text {
  text-align: left;
  margin-left: 40px;
}

.project-link {
  text-decoration: none;
  width: 35%;
  display: block;
}
.btn-wrap {
  display: "flex";
  min-width: "400px";
  justify-content: "space-evenly";
}

@media (max-width: 750px) {
  .projects-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
  }

  .skill {
    display: block;
    margin-top: 10px;
  }

  .project-link {
    width: 46%;
    text-decoration: none;
  }

  .btn-wrap {
    display: flex;
    min-width: 0px;
    justify-content: stretch;
    flex-direction: column;
  }
}
